import { Checkbox, Input, InputNumber, Tooltip } from 'antd';
import MyEditor from 'components/ui/FroalaEditor';
import { getKey } from 'functions'
import React, { useEffect, useState } from 'react'
import { Answer, Question, Quiz } from 'types';

const config = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: true,
    toolbarButtons: ['paragraphFormat', "|", 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', '|', 'align', 'formatOL', 'formatUL', 'quote', '-', 'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertTable', '|', 'undo', 'redo', 'clearFormatting', 'selectAll', 'help'],
    quickInsertButtons: ['image', 'video', 'embedly', 'table', 'ul', 'ol', 'hr'],
    quickInsertEnabled: false,
    events: {
        'initialized': function () {
            console.log('Editor initialized');
        }
    }
};


const defaultAnswer = [
    {
        id: getKey(15),
        correct: true,
        answer: "Yes",
        explanation: "",
        score: 0
    },
    {
        id: getKey(15),
        correct: false,
        answer: "No",
        explanation: "",
        score: 0

    }]


export default function YesNoQuestionForm(props: {
    question: Question,
    answer: Answer,
    updateAnswer: (obj: any) => void,
    updateQuestion: (obj: any) => void
    type: string,
    quiz?: Quiz
}) {
    const { question, answer: propsAnswer, updateAnswer, updateQuestion } = props
    const [answer, setAnswer] = useState(defaultAnswer);

    console.log(propsAnswer);

    useEffect(() => {
        if (propsAnswer) {
            setAnswer(propsAnswer);
        } else {
            updateAnswer(defaultAnswer)
        }
    }, [propsAnswer]);

    const handleContent = (value: string) => {
        updateQuestion({ name: "question", value: value })
    }

    const handleAnswerChange = (incomingAnswer: any) => {
        // console.log(id, c, a);
        let updatedAnswer = answer.map((ans) => {
            return { ...ans, correct: false };
        });

        // console.log(newAnswer);

        updatedAnswer = updatedAnswer.map((ans) => {
            if (ans.id === incomingAnswer.id) {
                // console.log(ans.id, id);
                return incomingAnswer
            }
            return ans;
        });
        updateAnswer(updatedAnswer);
    };

    return (
        <div className="question-box" datatype="Option">
            <div className="question-field form-box">
                <div className="label">Question</div>
                <MyEditor
                    key={"question-field"}
                    config={config}
                    content={question.question}
                    onContentChange={handleContent}
                />
            </div>

            <hr />
            <div className="options mt-5">
                <div className="flex-group mb-4">
                    <div className="title is-6 m-0">Options</div>
                </div>
                {answer &&
                    answer.map((ans) => (
                        <OptionInput
                            key={ans.id.toString()}
                            ans={ans}
                            handleAnswerChange={handleAnswerChange}
                            {...props}
                        />
                    ))}
            </div>
        </div>
    );
}


function OptionInput(props: {
    question: Question,
    ans: Answer,
    handleAnswerChange: (a: any) => void,
    type: string,
    quiz?: Quiz

}) {
    const { question, ans, handleAnswerChange, type, quiz } = props;
    console.log(ans);

    const [answer, setAnswer] = useState(ans)
    const [toggleExplanation, setToggleExplanation] = useState(ans.explanation ? true : false)

    const [needUpdate, setNeedUpdate] = useState(false)

    useEffect(() => {
        if (needUpdate) {
            handleAnswerChange(answer);
            setNeedUpdate(false)
        }
    }, [answer, needUpdate]);

    const handleChange = ({ name, value }: any) => {
        setAnswer((prev: any) => ({
            ...prev, [name]: value
        }))

        setNeedUpdate(true)

    };

    const handleToogle = () => setToggleExplanation(prevToggle => !prevToggle)


    console.log(type)
    return (

        <div className={type !== "task" && quiz.score_basis === 2 ? "option-answer-item with-score" : "option-answer-item"} >
            <div className={"answer-box " + type} response-type={question.response_type}>
                <Checkbox checked={ans.correct}
                    onChange={(e) => handleChange({ name: "correct", value: e.target.checked })}
                    className={type === "task" ? "input-check non" : "input-check"}>is True</Checkbox>

                {type !== "task" && quiz.score_basis === 2 &&
                    <Tooltip title={"Score"}>
                        <InputNumber
                            onChange={(e) => handleChange({ name: "score", value: e })}
                            className='input-score' value={ans.score} />
                    </Tooltip>
                }
                <Input
                    placeholder='Option'
                    className='input-value'
                    value={answer.answer}
                    onChange={(e) => handleChange({ name: "answer", value: e.target.value })}
                />
            </div>
            {type !== "task" && type !== "Task" &&
                <div className="explanation-box">
                    {toggleExplanation ?
                        <>
                            <div className="button-link is-danger mb-2" onClick={handleToogle}>- Explanation</div>
                            <div className="field">
                                <div className="control">
                                    <MyEditor
                                        config={config}
                                        placeholder="Explanation"
                                        content={answer.explanation}
                                        onContentChange={(value) => handleChange({ name: "explanation", value: value })}
                                    />

                                </div>
                            </div>
                        </> :
                        <div className="button-link" onClick={handleToogle}>+ Explanation</div>
                    }

                </div>
            }
        </div>
    )
}
